.container {
	position: relative;
	text-align: left;
	margin-bottom: 2rem;
	font-family: 'Montserrat';
	color: #333333;
	input {
		padding-right: 3rem;
		height: 3.5rem;
		width: 18rem;
	}
}
.arrow {
	position: absolute;
	right: 1rem;
	transform: translateY(-50%);
	top: 50%
}
