@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin.scss";

.ag-theme-material {
  @include ag-theme-material((
    odd-row-background-color: #f4f4f7,
    cell-horizontal-padding:  ag-derived(grid-size, $times: 1),
    font-family: 'Montserrat'
  ));
  .ag-row {
    font-size: 14px;
    color: #333333;
    font-weight: 500;
    border-width: 2px;
  }
  .ag-header-cell-text {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
  }
  .ag-header-cell-label, .ag-header-cell-text {
    white-space: normal; 
    overflow-wrap: normal;
    text-overflow: clip; 
    text-align: center;
    overflow: visible;
  }
  .ag-header-row {
    border-bottom: 2px solid #707070;
  }
  .ag-cell-label-container {
    width: auto;
    justify-content: flex-end;
  }
  .ag-body-viewport.ag-layout-normal{
    overflow-y: inherit;
  }
  .ag-paging-panel {
    justify-content: center;
    color: #333333;
  }
  .ag-paging-row-summary-panel {
    display: none;
  }
}
.grid-cell-centered {
  text-align: center;
}
.header-cell-centered {
  justify-content: center;
  font-size: 16px;
}
.no-focus:focus, .grid-cell-centered:focus{
  border: none !important;
  outline: none;
}
