.flex {
	display: flex;
	width: 100%;
	align-items: flex-end;
}
.middle {
	margin: 0 1rem 0 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 1.4rem;
	margin-top: auto;
	height: 100%;
}
