.container {
	position: relative;
	width: 64%;
	text-align: left;
	margin-bottom: 2rem;
	font-family: 'Montserrat';
	color: #333333;
	transition: all 0.5s ease;
}
.arrow {
	position: absolute;
	right: 1rem;
	transform: translateY(-50%);
	top: 50%
}
.dropdown {
	button {
		border-left: 4px solid #002664 !important;
		padding-bottom: 0;
	}
	div {
		padding-top: 0;
		transform: translate3d(0px, 42px, 0px) !important;
		button {
			padding-top: 1rem;
		}
	}
}