.loginContainer {
  min-height: 82vh;
  position: relative;
}
.loginGrid {
  width: 40vw;
}
.mrgnSml {
  margin-bottom: 0.8rem;
}
.mrgn {
  margin-bottom: 2.5rem;
}
