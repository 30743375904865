.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 82vh;
  width: 100%;
}

.flex {
  display: flex;
  width: 80vw;
  justify-content: space-between;
}

.header {
  color: #333333;
  font-family: 'Montserrat';
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.dd {
  img {
    display: none;
  }
	border-radius: 10px;
	padding: 0 0.5rem;
	width: 100%;
	height: 3.5rem;
	font-size: 1rem;
	border-radius: 5px;
	border: 2px solid #002664;
	background-color: transparent;
	cursor: pointer;
  & > div > :nth-child(2) {
    max-height: 15rem !important;
    width: 11rem !important;
    transform: translate3d(-10px, 54px, 0px) !important;
  }
  & > div > button {
    background-color: transparent !important;
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center !important;
    color: #002664 !important;
  }
}