.container {
  display: flex;
  width: 80vw;
  justify-content: space-between;
  align-items: center;
  margin: 2rem auto 0 auto;
  a, p {
    font-family: 'Montserrat';
    font-weight: 500;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
  }
}

.arrow {
  transform: rotate(90deg);
  object-fit: contain;
  height: 0.3rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}