// See gris.scss

.editBtn {
    font-family: 'Montserrat';
    color: #002664;
    font-weight: 400;
    border: none;
    background-color: transparent;
    &:hover {
        font-weight: 500;
    }
}