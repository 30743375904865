.container {
	position: relative;
	width: 100%;
	text-align: left;
	margin-bottom: 1rem;
}

.label {
	margin-bottom: 0.4rem;
	font-family: 'Montserrat';
	font-weight: 600;
}

.inputContainer {
	position: relative;
	width: 100%;
}

.alert {
	position: absolute;
	right: 1rem;
	transform: translateY(-50%);
	top: 50%
}