.flex {
	display: flex;
}
.font {
	font-family: 'Montserrat';
	font-weight: 500;
}
.modal {
	position: fixed;
	z-index: 10;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0026647a;
}
.container {
	position: fixed;
	background: white;
	width: 50%;
	text-align: center;
	border-radius: 1rem;
	height: 20rem;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	padding: 2rem 3rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;	
}