.flex {
	display: flex;
	width: 100%;
}
.half {
	width: 50%;
}

.cancel {
	margin-left: 1rem;
}

.mrgnSml {
	margin-bottom: 0.8rem;
}
.mrgn {
	margin-bottom: 2rem;
}

.editBtn {
	margin-bottom: 1rem;
	font-family: 'Montserrat';
	font-weight: 600;
	padding: 0 1rem;
	border: none;
	height: 3.5rem;
	font-size: 1rem;
	color: #c53040;
	background-color: transparent;
	cursor: pointer;
}