.container {
	position: relative;
	width: 100%;
	text-align: left;
	margin-bottom: 2rem;
	font-family: 'Montserrat';
	color: #333333;
	input {
		background-color: #ffffff !important;	
		height: 52px !important;
		border: 1px solid #a0a0a0;
		border-radius: 0.3rem;
	}
}

.label {
	margin-bottom: 0.4rem;
	font-family: 'Montserrat';
	font-weight: 600;
}

.menu button:hover {
	font-weight: bold ;
	background-color: #e9e9f0;
}

.inputContainer {
	position: relative;
	width: 100%;
}

.label {
	margin-bottom: 0.4rem;
}

.arrow {
	position: absolute;
	right: 1rem;
	transform: translateY(-50%);
	top: 50%
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

sup {
  color: red;
}